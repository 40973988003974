
.middle-box {
  max-width: 800px;
}
.app-img {
  width: 200px;
  display:block;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:15px;
  border-radius: 15px;
}
.ibox-title {
  padding-left:15px;
  padding-right:15px;
}

